import React from 'react';
import SEO from '../../components/seo';
import Waleria from '../../components/desktop/Waleria/Waleria';

const Artist = () => {
  return (
    <>
      <SEO title={'Willa Waleria'} />
      <Waleria />
    </>
  );
};

export default Artist;
