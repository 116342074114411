import React from 'react';
import {
  ColorWrapper,
  Container,
  ImageMove,
  PageContainer,
  TextSpaceMargin,
} from './styles';
import {SectionText, SectionTitle} from '../../atoms';
import img1 from './images/1.jpg';
import img2 from './images/2.jpg';
import img3 from './images/3.jpg';
import img4 from './images/4.jpg';
import img5 from './images/5.jpg';
import img6 from './images/6.jpg';
import img7 from './images/7.jpg';
import img8 from './images/8.jpg';
import img9 from './images/9.jpg';
import img10 from './images/10.jpg';
import img11 from './images/11.jpg';
import img12 from './images/12.jpg';
import img13 from './images/13.jpg';
import img14 from './images/14.jpg';
import img15 from './images/15.jpg';
import img16 from './images/16.jpg';
import img17 from './images/17.jpg';
import img18 from './images/18.jpg';
import img19 from './images/19.jpg';
import img20 from './images/20.jpg';
import img21 from './images/21.jpg';
import img22 from './images/22.jpg';
import img23 from './images/23.jpg';
import img24 from './images/24.jpg';
import img25 from './images/25.jpg';
import img26 from './images/26.jpg';
import img27 from './images/27.jpg';
import img28 from './images/28.jpg';
import img29 from './images/29.jpg';
import img30 from './images/30.jpg';
import img31 from './images/31.jpg';
import img32 from './images/32.jpg';
import img33 from './images/33.jpg';
import img34 from './images/34.jpg';
import img35 from './images/35.jpg';
import img36 from './images/36.jpg';
import img37 from './images/37.jpg';
import img38 from './images/38.jpg';
import img39 from './images/39.jpg';
import img40 from './images/40.jpg';
import img41 from './images/koszuta/053_anagl_altana_2.jpg';
import img42 from './images/koszuta/057_nagl_widok_z_okna_walerii3.jpg';
import img43 from './images/koszuta/058_anagl_bazarek3.jpg';
import img44 from './images/koszuta/091_waleria_anagl.jpg';
import img45 from './images/koszuta/092_waleria_anagl.jpg';
import img46 from './images/koszuta/093_waleria_aleja_anagl.jpg';
import img47 from './images/koszuta/094_piotra_skargi_anagl.jpg';
import img48 from './images/koszuta/095_zakopianka_anagl.jpg';
import img49 from './images/koszuta/097_muszka_anagl.jpg';
import img50 from './images/koszuta/097_stacja_anagl.jpg';
import img51 from './images/koszuta/098_altana_anagl.jpg';
import img52 from './images/koszuta/099_chrzan_anagl.jpg';
import moving from './images/waleria_01.png';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';

const Waleria = () => {
  return (
    <PageContainer backgroundColor={'#afa3c0'} style={{paddingBottom: 0}}>
      <Container>
        <ImageMove src={moving} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color={'#525252'}>WILLA WALERIA </SectionTitle>
            <PlayerGrey src={require('../../../audio/WILLA WALERIA.mp3')} />
          </div>
          <SectionText color={'#525252'} style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;Willa Waleria jest dziełem Rufina Morozowicza. Kierował
            on sugestie architektoniczne oraz był inwestorem. Willa została
            wzniesiona w 1910 r. dla jego żony Walerii z&nbsp;Kotowiczów
            i&nbsp;to jej budynek zawdzięcza swoje miano. Dwupiętrowy budynek
            stanął na zakupionych kilku działkach, powstałych po parcelacji
            terenu Milanówek Las-Górny. Posesja znajdowała się pomiędzy
            dzisiejszymi ulicami: Spacerową, Krasińskiego, Wojska Polskiego
            i&nbsp;Mickiewicza. Prace budowlane prowadził Rudzki
            z&nbsp;Grodziska Mazowieckiego. W&nbsp;dwupiętrowej formie budynek
            przetrwał do roku 1915. Ostatnia kondygnacja budynku spłonęła
            w&nbsp;tym czasie od wybuchu szrapnela. Odbudowy w&nbsp;1920 r.
            ponownie podjął się Rufin Morozowicz, jednakże tym razem powstał
            budynek jednopiętrowy. Po śmierci Rufina oraz Walerii Morozowiczów
            willa została w&nbsp;1931 r. własnością Marii
            Morozowicz-Szczepkowskiej – ich córki.
            <br />
            <br />
            &emsp;&emsp;Waleria była domem otwartym. Nie sposób wymienić gości,
            którzy pojawiali się u&nbsp;Morozowiczów i&nbsp;Szczepkowskich.
            <br />
            <br />
            &emsp;&emsp;Parterową część domu od strony ulicy Krasińskiego
            przeznaczono na pracownię Jana Szczepkowskiego. Na piętrze tej samej
            części urządzono dużą salę jadalną dla Szczepkowskich, która była
            wariantem wystroju oraz wyposażenia projektu wykonanego przez
            J.&nbsp;Szczepkowskiego dla Zamku Królewskiego w&nbsp;Warszawie.
            <br />
            <br />
            &emsp;&emsp;Wyjątkowo prezentował się ogród willi Waleria. Zgodnie
            z&nbsp;projektem Morozowicza na całej nieruchomości wytyczono
            alejki, altany, oranżerie i&nbsp;cieplarnie. Teren ozdabiały rzeźby
            i&nbsp;kapliczki w otoczeniu drzew i&nbsp;krzewów. Całość była
            ogrodzona białym drewnianym płotem.
            <br />
            <br />
            &emsp;&emsp;Od 1982 budynek wpisany jest do rejestru zabytków
            województwa warszawskiego, a&nbsp;następnie mazowieckiego. Na
            przełomie lat 90. XX w. i&nbsp;2000. z&nbsp;części jej pomieszczeń
            korzystało Społeczne Liceum Ogólnokształcące w&nbsp;Milanówku.
            <br />
            <br />
            &emsp;&emsp;Willa jest zbudowana w&nbsp;stylu zmodernizowanego
            historyzmu. Można dopatrzyć się w&nbsp;niej elementów dworu
            polskiego. Wzniesiona jest na nieregularnym planie, zbliżonym do
            prostokąta. Elewacja od strony wschodniej jest siedmioosiowa,
            z&nbsp;trójstronnym ryzalitem środkowym wraz z&nbsp;gankiem
            kolumnowym ze schodami do ogrodu, który był niegdyś prawdziwą ozdobą
            budynku. Obecnie to jednopiętrowy murowany budynek z&nbsp;mansardą,
            kryty dachówką. Nad gankiem dodatkowo znajduje się taras, a&nbsp;w
            mansardzie loggia z&nbsp;filarami. Na skrzydłach budynku znajdowały
            się oszklone werandy.
            <br />
            <br />
            &emsp;&emsp;Jak wspomina w&nbsp;swoich wspomnieniach Maria
            Morozowicz-Szczepkowska, był to „prawdziwy Raj”.
            <br />
            <br />
            &emsp;&emsp;Budynek został zakupiony w&nbsp;2007 r. przez Gminę
            Milanówek. W&nbsp;roku 2020 Miastu udało się uzyskać dwie dotacje na
            przywrócenie budynkowi dawnego blasku. Dzięki prawie 8,5 mln środków
            zewnętrznych już niedługo Waleria stanie się miejscem na działania
            lokalne i ponadlokalne związane z&nbsp;szeroko pojętą kulturą
            i&nbsp;edukacją. Pojawi się stała ekspozycja dzieł artysty,
            W&nbsp;odrestaurowanej pracowni odbywać będą się warsztaty rzeźby,
            ceramiki, snycerstwa i&nbsp;innych form artystycznych bliskich
            Janowi Szczepkowskiemu. Waleria stanie się miejscem koncertów,
            wykładów i&nbsp;spotkań gdzie kultura i&nbsp;sztuka będą na
            pierwszym miejscu. <br />
            <br />
          </SectionText>
          <Container style={{marginTop: '2rem'}}>
            <p
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}>
              Źródła informacji:
              <br />
              <br />
              Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym,
              Niepodległość i Pamięć 17/1 (31), 257-271, 2010
              <br />
              <br />
              Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło
              (1878-1964), Milanówek Miejsce Magiczne pod red. Andrzeja Pettyna,
              Towarzystwo Miłośników Milanówka, 2005
              <br />
              <br />
              Chrudzimska-Uhera Katarzyna, Jan Szczepkowski, Życie i twórczość,
              Urząd Miejski w Milanówku, Milanówek 2008 r.
              <br />
              <br />
              Koszuta Mariusz, Spacer z Walerią po letnisku Milanówek,
              Wydawnictwo Impress, Warszawa 2010 r.
              <br />
              <br />
              Morozowicz – Szczepkowska Maria, Z lotu ptaka, Państwowy Instytut
              Wydawniczy, Warszawa, 1968
              <br />
              <br />
              Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp,
              opracowanie i komentarz Katarzyna Chrudzimska - Uhera, Urząd
              Miasta Milanówka, Milanówek, 2010
            </p>
          </Container>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper
        bgColor={'#525252'}
        style={{marginTop: '3rem', padding: '2rem 0'}}>
        <Container>
          <Gallery
            columnsNumber={4}
            isImgFit
            style={{gridGap: '3rem 1rem'}}
            hoverColor="rgba(176, 163, 193, 0.4)"
            colorSign="#fff"
            images={[
              {
                src: img1,
                sign: 'Aleja ogrodowa',
              },
              {
                src: img2,
                sign: 'Aleja ogrodowa',
              },
              {
                src: img3,
                sign: 'Aleja zimową porą',
              },
              {
                src: img4,
                sign: 'Alejka w ogrodzie willi Waleria',
              },
              {
                src: img5,
                sign: 'Altana ogrodu na sztucznie utworzonym podwyższeniu',
              },
              {
                src: img6,
                sign: 'Altanka ogrodowa',
              },
              {
                src: img7,
                sign: 'Brama wejściowa do Willi Waleria',
              },
              {
                src: img8,
                sign: 'Budowa ogrodzenia posesji Walerii',
              },
              {
                src: img9,
                sign:
                  'Kapliczka Matki Boskiej ustawiona u zbiegu obecnych ulic Wojska Polskiego i Krasińskiego. Obok stojąca Waleria Morozowicz.',
              },
              {
                src: img10,
                sign: 'Leśna aleja spacerowa z altanami w tle',
              },
              {
                src: img11,
                sign: 'Leśny zakątek ogrody Willi Waleria',
              },
              {
                src: img12,
                sign: 'Ogrodzenie posesji',
              },
              {
                src: img13,
                sign: 'Ogrodzenie willi Waleria',
              },
              {
                src: img14,
                sign: 'Ogrodzenie willi Waleria',
              },

              {
                src: img15,
                sign: 'Salon Marii i Jana w willi Waleria',
              },
              {
                src: img16,
                sign: 'Spacer po ogrodzie willi Walerii',
              },
              {
                src: img17,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img18,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img19,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img20,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img21,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img22,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img23,
                sign: 'Waleria w chwili budowy',
              },
              {
                src: img24,
                sign:
                  'Widoczna w tle Waleria Morozowicz wśród materiałów budowlanych',
              },
              {
                src: img25,
                sign: 'Widoczne białe ogrodzenie posesji willi Waleria',
              },
              {
                src: img26,
                sign:
                  'Widok na Chrzanów Mały z okolic bramy zachodniej, przy obecnej ul. Wojska Polskiego',
              },
              {
                src: img27,
                sign: 'Widok na szklarnie oraz wille w trakcie budowy',
              },
              {
                src: img28,
                sign: 'Widok na ulicę Krasińskiego',
              },
              {
                src: img29,
                sign: 'Widok ogrodu w kierunku ulicy Krasińskiego',
              },
              {
                src: img30,
                sign: 'Widok z okna na ogród od strony zachodniej',
              },
              {
                src: img31,
                sign: 'Widok zachodniej części willi Waleria podczas budowy',
              },
              {
                src: img32,
                sign: 'Willa Waleria w całej okazałości',
              },
              {
                src: img33,
                sign: 'Willa Waleria, widok od ulicy Spacerowej',
              },
              {
                src: img34,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówk. Widok od strony południowej.',
              },
              {
                src: img35,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Element architektoniczny.',
              },
              {
                src: img36,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Schody prowadzące do wejścia. Obecnie nieistniejąće.',
              },
              {
                src: img37,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Taras nad wejściem.',
              },
              {
                src: img38,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Wejście od ul. Spacerowej.',
              },
              {
                src: img39,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Widok na dawną fontannę.',
              },
              {
                src: img40,
                sign:
                  'Zdjęcie wykonane przez Helenę Wesołowską.  Ok. 1978 r. CATL w Milanówku. Widok na wejście od strony ul. Spacerowej.',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#525252'}>
        <Container>
          <SectionText style={{textAlign: 'center', color: '#ffffff'}}>
            Fotografie anaglifowe z albumu "Spacer z Walerią" autorstwa Mariusza
            Koszuty.
          </SectionText>
          <Gallery
            columnsNumber={4}
            isImgFit
            hoverColor="rgba(176, 163, 193, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: img41,
                sign: '',
              },
              {
                src: img42,
                sign: '',
              },
              {
                src: img43,
                sign: '',
              },
              {
                src: img44,
                sign: '',
              },
              {
                src: img45,
                sign: '',
              },
              {
                src: img46,
                sign: '',
              },
              {
                src: img47,
                sign: '',
              },
              {
                src: img48,
                sign: '',
              },
              {
                src: img49,
                sign: '',
              },
              {
                src: img50,
                sign: '',
              },
              {
                src: img51,
                sign: '',
              },
              {
                src: img52,
                sign: '',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default Waleria;
